import Vue from "vue";
import {
    Button,
    Notification,
    MessageBox,
    Message,
    Carousel,
    CarouselItem,
    Dialog,
    Tooltip,
    Form,
    FormItem,
    Input,
    InputNumber,
    Image,
    Tabs,
    TabPane,
    Cascader,
    Table,
    TableColumn,
    Pagination,
    Popover,
    Select,
    Option,
    Checkbox,
    Dropdown,
    Radio,
    DropdownItem,
    DropdownMenu,
    Loading,
    Rate,
    Badge,
    Row,
    Col,
    Menu,
    MenuItem,
    Submenu,
    MenuItemGroup,
    DatePicker,
    Descriptions,
    DescriptionsItem,
    Switch,
    CheckboxGroup,
    Upload,
    Popconfirm,
    RadioGroup,
    Drawer,
    Tree,
    Progress,
    Divider
} from "element-ui";

Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dialog);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Image);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Cascader);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Rate);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Loading);
Vue.use(Badge);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(DatePicker);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Switch);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(Popconfirm);
Vue.use(Drawer);
Vue.use(Tree);
Vue.use(Progress);
Vue.use(Divider)
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$Loading = Loading;

/*Blocked aria-hidden on an element
* 报错的解决方法*/
Vue.directive('removeAriaHidden',{
    bind(el,binding){
       let ariaEls = el.querySelectorAll('.el-radio__original');
       ariaEls.forEach(item=>{
           item.removeAttribute('aria-hidden')
       })
    }
})
