/*
立项*/
import axios from "../axios";

/***
 * 项目列表
 * @param params
 */
export const projectAllList = params => {
    return axios({
        url: "/api/project/list",
        method: "get",
        params
    });
};

/***
 * 新增项目
 * @param params
 */
export const saveNewProject = data => {
    return axios({
        url: "/api/project/save",
        method: "post",
        data
    });
};
/***
 * 编辑回显
 * @param params
 */
export const projectEditInfo = params => {
    return axios({
        url: "/api/project/edit/info",
        method: "get",
        params
    });
};
/***
 * 查询品类库树形结果
 * @param params
 */
export const categoryList = params => {
    return axios({
        url: "/api/bidding/dataInit",
        method: "get",
        params
    });
};

/***
 * 查看-基本信息
 * @param params
 */
export const base_infoView = bidProjectId => {
    return axios({
        url:`/api/project/base_info/view/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 查看-基本信息
 * @param params
 */
export const getfile_info = bidProjectId => {
    return axios({
        url:`/api/project/file_info/view/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 查看-项目进展
 * @param params
 */
export const project_processView = bidProjectId => {
    return axios({
        url:`/api/project/project_process/view/${bidProjectId}`,
        method: "get"
    });
};
/***
 * 查看-标段信息
 * @param params
 */
export const section_infoView = bidProjectId => {
    return axios({
        url:`/api/project/section_info/view/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 查看-采购人信息
 * @param params
 */
export const purchaser_infoView = bidProjectId => {
    return axios({
        url:`/api/project/purchaser_info/view/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 查看-采购明细
 * @param params
 */
export const procurement_detailsView = params => {
    return axios({
        url:`/api/project/procurement_details/view`,
        method: "get",
        params
    });
};
/***
 * 删除
 * @param params
 */
export const deleteProject = bidProjectId => {
    return axios({
        url:`/api/project/delete/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 撤销
 * @param params
 */
export const revokeProject = bidProjectId => {
    return axios({
        url:`/api/project/revoke/${bidProjectId}`,
        method: "get"
    });
};
/***
 * 获取经办人
 * @param params
 */
export const purchaserList = params => {
    return axios({
        url:`/api/group/purchaser/list`,
        method: "get",
        params
    });
};

/***
 * 发展集团组织机构树
 * @param params
 */
export const deptCFZList = params => {
    return axios({
        url:`/api/dept/list`,
        method: "get",
        params
    });
};

/***
 * 部门采购人
 * @param params
 */
export const peasonCFZList = params => {
    return axios({
        url:`/api/dept/purchaser/list`,
        method: "get",
        params
    });
};

/***
 * 分配经办人
 * @param params
 */
export const peasonDistribute = data => {
    return axios({
        url:`/api/project/distribute`,
        method: "post",
        data
    });
};
/***
 * 分配详情
 * @param params
 */
export const peasonDistributeDetail = bidProjectId => {
    return axios({
        url:`/api/project/distribution/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 新增-回显项目信息
 * @param params
 */
export const re_biddingDetail = params => {
    return axios({
        url:`/api/project/re_bidding/info`,
        method: "get",
        params
    });
};
/***
 * 重新招标保存
 * @param params
 */
export const addRe_biddingDetail = data => {
    return axios({
        url:`/api/project/re_bidding/add`,
        method: "post",
        data
    });
};
/***
 * 重新招标编辑
 * @param params
 */
export const saveRe_biddingDetail = data => {
    return axios({
        url:`/api/project/re_bidding/save`,
        method: "post",
        data
    });
};


/***
 * 获取零星小额项目详情
 * @param params
 */
export const getSmallDetal = params => {
    return axios({
        url:`/api/supplement/small_detail/${params} `,
        method: "get"
    });
};



/***
 * 导入项目立项采购明细模版
 * @param data
 * @returns {*|Promise<any>}
 */
export  const importProjectPlanItem =data=> {
    return axios({
        url: '/api/plan/importProjectPlanItem',
        method: "post",
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
/***
 * 项目立项列表导出
 * @param data
 * @returns {*|Promise<any>}
 */
export  const projectdownload =params=> {
    return axios({
        url: '/api/project/list/download',
        method: "get",
        params,
        responseType:'blob'
    })
};
/***
 * 采购明细导出
 * @param data
 * @returns {*|Promise<any>}
 */
export  const itemExport =data=> {
    return axios({
        url: '/api/project/section/item/export',
        method: "post",
        data,
        responseType:'blob'
    })
};