const stateConfig = window.globalConfig.stateConfig;
const common = {};

function isArray(value) {
    return Object.prototype.toString.call(value) === '[object Array]'
}

Object.assign(common, {
    getConfigOptions(configName) {
        let list = [];
        if (stateConfig) {
            list = stateConfig[configName];
            //修改字段名
            if (list) {
                list = list.map(v => {
                    return {label: v.name, value: v.id};
                });
            }

        }
        return list;
    },

    getConfigList(configName, value, property = 'id') {
        if (!stateConfig) {
            return;
        }
        const list = stateConfig[configName];
        if (list && list.length > 0) {
            return list;
        }
    },

    //阿拉伯数字转换为大写
    amountLtoU(amount) {
        if (isNaN(amount) || amount >= 1000000000000) {
            return "无效金额！"; //数值最大不超过1万亿
        }
        let sPrefix = amount < 0 ? "(负)" : ""; //将负号‘-’显示成汉字‘(负)’
        let sAmount = Math.abs(amount).toFixed(2); //格式化成两位小数格式（两位小数对应“'角”和“分”）
        let sUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'; //14个权位单位
        let sCapital = '零壹贰叁肆伍陆柒捌玖'; //10个大写数字
        sAmount = sAmount.replace(".", ""); //去除小数点（使数字与权位对应）
        sUnit = sUnit.substr(sUnit.length - sAmount.length); //截取权位
        let sOutput = "";
        for (var i = 0, len = sAmount.length; i < len; i++) { //拼接数字与权位单位
            sOutput += sCapital.substr(sAmount.substr(i, 1), 1) + sUnit.substr(i, 1);
        }
        return sPrefix + sOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零')
            .replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元");
    },
    /*
        @筛选出匹配状态码的对象
        @params {string} configName: 配置状态名称
        @params {string} property: 属性名称 默认为 id
        @params {string | number} value: 属性值
        @return {Object} 返回匹配的对象
    */
    filterStateObj(configName, value, property = "id") {
        //默认值，避免无值时报错
        let obj = {};
        if (stateConfig) {
            var list = stateConfig[configName];
            if (list && list.length > 0) {
                obj = list.find(a => a[property] == value ||  a[property] == parseInt(value, 10));
            }
        }
        return obj;
    },

    /**
     * 获取配置状态
     * */
    getConfigState(configName, code) {
        let id = "";
        if (stateConfig) {
            var list = stateConfig[configName];
            if (list && list.length > 0) {
                var obj = list.find(a => a.code === code);
                id = obj.id;
            }
        }
        return id;
    },
    /**
     * 获取配置状态名字
     * */
    getConfigNameState(configName, value, property = "id") {
        let text = "";
        if (stateConfig) {
            var list = stateConfig[configName];
            if (list && list.length > 0) {
                var obj = list.find(a => a[property] == parseInt(value, 20) || a[property] == value);
                if (obj) {
                    text = obj.name;
                }
            }
        }
        return text;
    },
    /***
     * 列表上的按钮权限
     * @param arry
     * @param key
     * @returns {boolean}
     */
    hasListButtonPermission(arry, key) {
        let result = false;
        if (arry && arry.length > 0) {
            let obj = arry.find(a => a === key);
            if (obj) {
                result = true
            }
        }
        return result
    },
    /***
     * 验证
     * @param arry
     * @param key
     * @returns {boolean}
     */
    rulesLimi(label) {
        let name = "";
        if (label) {
            name = label
        } else {
            name = "此项"

        }
        return [{required: true, message: name + "不能为空", trigger: ['blur']}]
    },
    rulesLimiChange(label) {
        let name = "";
        if (label) {
            name = label
        } else {
            name = "此项"

        }
        return [{required: true, message: name + "不能为空", trigger: ['blur', 'change']}]
    },
    //输入框
    checkPrice(e, flag = null, max) { //flag==1  去掉数字最后的.
        if(e.target.value !== ''){
            e.target.value = e.target.value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
                .replace(/^\./g, '') // 保证第一个为数字而不是.
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.');
            if (e.target.value.indexOf('.') < 0 && e.target.value !== '') {
                // 如果没有小数点，首位不能为类似于 01、02的金额
                e.target.value = parseFloat(e.target.value) + '';
            } else if (e.target.value.indexOf('.') >= 0) {
                //只保留两位小数
                if (flag == 1) {
                    if (e.target.value.split('.').length > 1 && e.target.value.split('.')[1].length > 0) {
                        e.target.value = e.target.value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
                    } else {
                        //失去焦点时若最后一位是. 则删除
                        e.target.value = e.target.value.replace(/\./g, '');
                    }
                } else {
                    e.target.value = e.target.value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
                }
            }
            if (flag == 1) {
                e.target.value = e.target.value - 0;
                // if (e.target.value == 0) {
                //     e.target.value = ""
                // }
                if (max < e.target.value) {
                    e.target.value = max
                }
            }
            let val = this.fmoney(e.target.value)
            return val;
        }else {
            return ''
        }

    },
    //字段转换
    checkPriceText(value) { //flag==1  去掉数字最后的.
        if(value){
            value=this.remoney(value);
            value = value.toString().replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
                .replace(/^\./g, '') // 保证第一个为数字而不是.
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.');
            if (value.indexOf('.') < 0 && value !== '') {
                // 如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value) + '';
            } else if (value.indexOf('.') >= 0) {
                //只保留两位小数
                if (value.split('.').length > 1 && value.split('.')[1].length > 0) {
                    value = value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
                } else {
                    //失去焦点时若最后一位是. 则删除
                    value = value.replace(/\./g, '');
                }
            }
            value = value - 0;
            let val = this.fmoney(value);
            return val;
        }else if(value==0){
            return '0.00';
        }

    },
    //千分位格式化：10000转为10,000.00
    fmoney(s, n) {
        if (s == '' || s == null || s == undefined | isNaN(Number(s))) {
            return s
        }
        s = Number(s) ? Number(s) : s
        n = ((n || n == 0) && n > -1 && n <= 20) ? n : 2;
        s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
        var l = s.split(".")[0].split("").reverse();
        var r = s.split(".")[1];
        var t = "";
        for (var i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
        }
        if (n > 0) {
            r = '.' + r
        } else if (n == 0) {
            r = ''
        }
        let returnT = t.split("").reverse().join("") + r;
        //负数处理
        if (returnT[0] == '-' && returnT[1] == ',') {
            returnT = '-' + returnT.substring(2)
        }
        return returnT;
    },
    //千分位反格式化:10,000.00转为10000.00
    remoney(s) {
        let str = '';
        if (s) {
            str = String(s).replace(/,/g, "")
        }else{
            return
        }
        if (s && (s + "").indexOf('.') > -1 && Number(str)) {
            return Number(String(s).replace(/[^\d\.-]/g, ""));
        } else if (s && Number(str)) {
            return Number(str);
        } else {
            return Number(s)
        }
    }
});

export default common;
