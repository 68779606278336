import axios from "../axios";

export const getList = params => {
    return axios({
        url: `/api/OnlineInvoice/authorityCompanyList`,
        method: "get",
        params
    });
};
export const delAuthority = params => {
    return axios({
        url: `/api/OnlineInvoice/delAuthority`,
        method: "get",
        params
    });
};
//开票权限单位的设置
export const authorityCompany = data => {
    return axios({
        url: `/api/OnlineInvoice/authorityCompany/operate`,
        method: "post",
        data
    });
};

export const getOrg = params => {
    return axios({
        url: `/api/authorityCompany/frontDeptList`,
        method: "get",
        params
    });
};


export const saveOrg = data => {
    return axios({
        url: `/api/OnlineInvoice/authorityCompany`,
        method: "post",
        data
    });
};

export const part_infoList = params => {
    return axios({
        url: `/api/agency/part_info/list`,
        method: "get",
        params
    });
};

export const addAgenceOrg = params => {
    return axios({
        url: `/api/OnlineInvoice/authority/agency/add`,
        method: "get",
        params
    });
};
