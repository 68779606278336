import Vue from "vue";
import VueRouter from "vue-router";

import SystemLayout from "../layout/SystemLayout";

import common from "./common";


Vue.use(VueRouter);

const routes = [
    // {
    // 	path: "/test",
    // 	// redirect: "/systemHome"
    // 	component: () => import( /* webpackChunkName: "lease"*/ "../views/test.vue")
    // },
    {
        path: "/transitionPage.html",
        name: "transitionPage",
        meta: {
            keepAlive: false
        },
        component: () => import( /* webpackChunkName: "lease"*/ "../views/transitionPage.vue")
    },
    // 订阅服务（提供给第四产权系统嵌入使用）
    {
        path: "/subscribeList",
        name: "subscribeList",
        meta: {
            keepAlive: false,
            pathName: "订阅服务",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/subscribe/list")
    },
    // 文件费（提供给第四产权系统嵌入使用）
    {
        path: "/fileFee",
        name: "fileFee",
        meta: {
            keepAlive: false,
            pathName: "文件费",
            showBreadCrumbs: true,
            code:'file'
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/invoicingRecord/file")
    },
    {
        path: "/subscribeFee",
        name: "subscribeFee",
        meta: {
            keepAlive: false,
            pathName: "订阅费",
            showBreadCrumbs: true,
            code:'file'
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/invoicingRecord/subscribe")
    },{
        path: "/fileFeeManage",
        name: "fileFee",
        meta: {
            keepAlive: false,
            pathName: "文件费",
            showBreadCrumbs: true,
            code:'manage'
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/invoicingRecord/file")
    },
    {
        path: "/subscribeFeeManage",
        name: "subscribeFee",
        meta: {
            keepAlive: false,
            pathName: "订阅费",
            showBreadCrumbs: true,
            code:'manage'
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/invoicingRecord/subscribe")
    },
    // 开票权限单位（提供给第四产权系统嵌入使用）
    {
        path: "/invoicingUnitList",
        name: "invoicingUnitList",
        meta: {
            keepAlive: false,
            pathName: "开票权限单位",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/invoicingUnit/list")
    },
    // 报价大厅（提供给第四产权系统嵌入使用）
    {
        path: "/quotationHall",
        name: "quotationHallForOldSystem",
        meta: {
            keepAlive: false,
            pathName: "报价大厅",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/
            "../views/flowPages/quotationHall/quotationHallForOldSystem")
    },
    // 报价澄清回复查看（提供给第四产权系统嵌入使用）
    {
        path: "/clearDetail",
        name: "clearDetail",
        meta: {
            keepAlive: false,
            pathName: "澄清回复查看",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/ "../views/flowPages/quotationHall/clearDetail")
    },
    // 新增采购文件
    {
        path: "/submitFile",
        name: "submitFile",
        meta: {
            keepAlive: false,
            pathName: "递交投标文件",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/ "../views/purchasingFile/submitFile")
    }, // 第四产权库内直接比价 的中标结果页面
    {
        path: "/bidResultKNZJBJ",
        name: "bidResultKNZJBJ",
        meta: {
            keepAlive: false,
            pathName: "递交投标文件",
            showBreadCrumbs: true
        },
        component: () => import( /* webpackChunkName: "lease"*/ "../views/bidResultKNZJBJ")
    },
    {
        path: "/",
        name: "SystemLayout",
        component: SystemLayout,
        children: [{
                path: "/systemHome",
                name: "systemHome",
                code: 'purchase:home',
                meta: {
                    keepAlive: false,
                    pathName: "首页",
                    showBreadCrumbs: false
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/SystemHome.vue")
            }, {
                path: "/projectList",
                name: "projectList",
                meta: {
                    keepAlive: false,
                    pathName: "项目列表",
                    showBreadCrumbs: false
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/tendersProjectManage/listManage/index.vue")
            }, {
                path: "/projectDetails",
                name: "projectDetails",
                meta: {
                    keepAlive: false,
                    pathName: "查看",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/tendersProjectManage/listManage/projectDetails.vue")
            }, {
                path: "/newProject",
                name: "newProject",
                meta: {
                    keepAlive: false,
                    pathName: "项目立项",
                    showBreadCrumbs: true
                },
                component: () => import(
                    "../views/tendersProjectManage/listManage/newProject.vue")
            }, {
                path: "/reBiddingProject",
                name: "reBiddingProject",
                meta: {
                    keepAlive: false,
                    pathName: "重新招标",
                    showBreadCrumbs: true
                },
                component: () => import(
                    "../views/tendersProjectManage/listManage/reBiddingProject.vue")
            },
            {
                path: "/projectQuery",
                name: "projectQuery",
                meta: {
                    keepAlive: false,
                    pathName: "项目查询",
                    showBreadCrumbs: true
                },
                component: () => import(
                    "../views/tendersProjectManage/projectQuery/index.vue")
            },
            {
                path: "/projectQueryDetail",
                name: "projectQueryDetail",
                meta: {
                    keepAlive: false,
                    pathName: "项目查询详情",
                    showBreadCrumbs: true
                },
                component: () => import(
                    "../views/tendersProjectManage/projectQuery/detail.vue")
            },
            {
                path: "/projectQueryNoticeDetail",
                name: "projectQueryNoticeDetail",
                meta: {
                    keepAlive: false,
                    pathName: "项目查询详情",
                    showBreadCrumbs: true
                },
                component: () => import(
                    "../views/tendersProjectManage/projectQuery/noticeDetail.vue")
            },
            {
                path: "/toDoIndex",
                name: "toDoIndex",
                code: "",
                meta: {
                    keepAlive: false,
                    pathName: "待办任务",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/Todo/toDoIndex.vue")
            }, {
                path: "/toDoDetails",
                name: "toDoDetails",
                code: "",
                meta: {
                    keepAlive: false,
                    pathName: "待办详情",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/Todo/toDoDetails.vue")
            }, {
                path: "/proxySelection",
                name: "proxySelection",
                code: "",
                meta: {
                    keepAlive: false,
                    pathName: "代理选取",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/Todo/proxySelection.vue")
            }, {
                path: "/proxySelectionDetails",
                name: "proxySelectionDetails",
                code: "",
                meta: {
                    keepAlive: false,
                    pathName: "代理选取",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/Todo/proxySelectionDetails.vue")
            }, {
                path: "/message",
                name: "message",
                meta: {
                    keepAlive: false,
                    pathName: "消息中心",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/Todo/message.vue")
            }, {
                path: "/plan",
                name: "plan",
                meta: {
                    keepAlive: false,
                    pathName: "采购计划",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/plan/index.vue")
            }, {
                path: "/addPlan",
                name: "addPlan",
                meta: {
                    keepAlive: false,
                    pathName: "新增采购计划",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/plan/components/addPlan.vue")
            }, {
                path: "/planDetail",
                name: "planDetail",
                meta: {
                    keepAlive: false,
                    pathName: "采购计划详情",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/plan/detail.vue")
            }, {
                path: "/planStatistics",
                name: "planStatistics",
                meta: {
                    keepAlive: false,
                    pathName: "采购计划统计",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/plan/planStatistics.vue")
            }, {
                path: "/additional/enter",
                name: "additionalEnter",
                meta: {
                    keepAlive: false,
                    pathName: "进场交易项目",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/enter.vue")
            }, {
                path: "/additional/small",
                name: "additionalSmall",
                meta: {
                    keepAlive: false,
                    pathName: "零星小额采购",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/small.vue")
            }, {
                path: "/additional/newSmall",
                name: "additionalNewSmall",
                meta: {
                    keepAlive: false,
                    pathName: "零星小额采购"
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/additional/components/newSmall.vue")
            }, {
                path: "/additional/form",
                name: "additionalForm",
                meta: {
                    keepAlive: false,
                    pathName: "采购结果补录",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/form.vue")
            },
            { //补录新版
                path: "/additional/newProject",
                name: "additionalNewProject",
                meta: {
                    keepAlive: false,
                    pathName: "采购结果补录",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/additional/newProject/index.vue")
            },
            // 进场补录详情
            {
                path: "/additional/additionalDetail",
                name: "additionalDetail",
                meta: {
                    keepAlive: false,
                    pathName: "详情",
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/additionalDetail")
            },
            // 进场补录详情新版
            {
                path: "/additional/newAdditionalDetail",
                name: "newAdditionalDetail",
                meta: {
                    keepAlive: false,
                    pathName: "详情",
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/additional/newProject/newDetails")
            },


            {
                path: "/template/index",
                name: "templateList",
                meta: {
                    keepAlive: false,
                    pathName: "模版管理",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/systemAbility/template/index")
            }, {
                path: "/template/addTemplate",
                name: "addTemplate",
                meta: {
                    keepAlive: false,
                    pathName: "新增模版",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/template/addTemplate")
            }, {
                path: "/price/index",
                name: "priceList",
                meta: {
                    keepAlive: false,
                    pathName: "中标价格库",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/systemAbility/price/index")
            },
            {
                path: "/collaborativePurchasing/index",
                name: "collaborativePurchasing",
                meta: {
                    keepAlive: false,
                    pathName: "协同采购目录",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/collaborativePurchasing/index")
            },
            {
                path: "/collaborativePurchasing/manage",
                name: "collaborativePurchasingmanage",
                meta: {
                    keepAlive: false,
                    pathName: "协同采购目录管理",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/collaborativePurchasing/manage")
            }, {
                path: "/additional/additionalSmall",
                name: "additionalSmall",
                meta: {
                    keepAlive: false,
                    pathName: "零星小额采购",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/smallForm.vue")
            }, {
                path: "/additional/small/detail",
                name: "additionalSmall",
                meta: {
                    keepAlive: false,
                    pathName: "零星小额采购",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/additional/smallDetail.vue")
            }, {
                path: "/abnormal/complain",
                name: "abnormalComplain",
                meta: {
                    keepAlive: false,
                    pathName: "投诉处理",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/abnormal/complain.vue")
            }, {
                path: "/abnormal/objectionDetail",
                name: "objectionDetail",
                meta: {
                    keepAlive: false,
                    pathName: "招标异议详情查看",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/abnormal/objectionDetail.vue")
            }, {
                path: "/abnormal/objectionReply",
                name: "objectionReply",
                meta: {
                    keepAlive: false,
                    pathName: "回复异议",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/abnormal/objectionReply.vue")
            }, {
                path: "/abnormal/terminate",
                name: "abnormalTerminate",
                meta: {
                    keepAlive: false,
                    pathName: "终止（异常）公告",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/abnormal/terminate.vue")
            }, {
                path: "/abnormal/terminate",
                name: "abnormalTerminate",
                meta: {
                    keepAlive: false,
                    pathName: "终止（异常）公告",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/abnormal/terminate.vue")
            }, {
                path: "/abnormal/terminateEdit",
                name: "abnormalTerminateEdit",
                meta: {
                    keepAlive: false,
                    pathName: "终止（异常）公告",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/abnormal/components/terminateEdit.vue")
            }, {
                path: "/abnormal/terminate/detail",
                name: "abnormalTerminateDetail",
                meta: {
                    keepAlive: false,
                    pathName: "终止（异常）公告",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/abnormal/components/terminateDetail.vue")
            }, {
                path: "/userInfo/baseInfo",
                name: "baseInfo",
                meta: {
                    keepAlive: false,
                    pathName: "基本信息设置",
                    showBreadCrumbs: true
                },
                component: () => import("../views/userInfo/baseInfo.vue")
            }, {
                path: "/userInfo/baseInfoGYS",
                name: "baseInfoGYS",
                meta: {
                    keepAlive: false,
                    pathName: "基本信息设置",
                    showBreadCrumbs: true
                },
                component: () => import("../views/userInfo/baseInfoGYS.vue")
            }, {
                path: "/userInfo/passwordChange",
                name: "passwordChange",
                meta: {
                    keepAlive: false,
                    pathName: "修改密码",
                    showBreadCrumbs: true
                },
                component: () => import("../views/userInfo/passwordChange.vue")
            },
            // 编制采购文件列表
            {
                path: "/purchasingFile",
                name: "purchasingFile",
                meta: {
                    keepAlive: false,
                    pathName: "编制采购文件",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/purchasingFile/index")
            },
            // 新增采购文件
            {
                path: "/addPurchasingFile",
                name: "addPurchasingFile",
                meta: {
                    keepAlive: false,
                    pathName: "新增采购文件",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/purchasingFile/addPurchasingFile")
            },
            // 新增采购文件
            {
                path: "/purchasingFileDetails",
                name: "purchasingFileDetails",
                meta: {
                    keepAlive: false,
                    pathName: "查看采购文件",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/purchasingFile/details")
            }, {
                path: "/orderForm/index",
                name: "orderForm",
                meta: {
                    keepAlive: false,
                    pathName: "采购订单",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/orderForm/index")
            }, {
                path: "/noticeManage/noticeManage",
                name: "noticeManage",
                meta: {
                    keepAlive: false,
                    pathName: "系统通知管理",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/noticeManage/noticeManage")
            }, {
                path: "/noticeManage/addNotice",
                name: "addNotice",
                meta: {
                    keepAlive: false,
                    pathName: "新增通知",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/noticeManage/addNotice")
            }, {
                path: "/noticeManage/noticeDetail",
                name: "noticeDetail",
                meta: {
                    keepAlive: false,
                    pathName: "通知详情",
                    showBreadCrumbs: true
                },
                component: () => import( /* webpackChunkName: "lease"*/
                    "../views/systemAbility/noticeManage/noticeDetail")
            }, {
                path: "/orderForm/luckyManage",
                name: "luckyManage",
                meta: {
                    keepAlive: false,
                    pathName: "京东锦礼管理",
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/orderForm/luckyManage")
            },
            {
                path: "/ratingTemplate/index",
                name: "ratingTemplateIndex",
                meta: {
                    keepAlive: false,
                    pathName: "评分表管理",
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/ratingTemplate/index")
            },
            {
                path: "/ratingTemplate/add",
                name: "ratingTemplateAdd",
                meta: {
                    keepAlive: false,
                    pathName: "评分表管理"
                },
                component: () => import( /* webpackChunkName: "lease"*/ "../views/ratingTemplate/add")
            },

        ]
    },
    {
        path: "/flowLayout",
        name: "FlowLayout",
        component: () => import( /* webpackChunkName: "lease"*/ "../layout/FlowLayout.vue"),
        children: [
            ...common,
        ]
    },
    {
        path: "*",
        name: "404",
        component: () => import("../views/NotFound")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // 里面判断进行路由拦截
    next();
});

// 解决路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location)["catch"](err => err);
};
export default router;