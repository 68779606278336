import axios from "../axios";

/**
 * 计划管理
 */

/***
 * 采购计划列表
 * @param params
 */
export const getPlanList = params => {
    return axios({
        url: "/api/plan/list",
        method: "get",
        params
    });
};
/***
 * 采购明细列表
 * @param params
 */
export const getPlanListMing = params => {
    return axios({
        url: "/api/plan/item/list",
        method: "get",
        params
    });
};

/***
 * 计划条目统计列表
 * @param params
 */
export const statisticsList = params => {
    return axios({
        url: "/api/plan/plan_item/list/all",
        method: "get",
        params
    });
};

/***
 * 组织机构树
 * @param params
 */
export const deptsTreeList = params => {
    return axios({
        url: "/api/plan/depts/tree",
        method: "get",
        params
    });
};
/***
 * 网上商城 -- 查询计划条目
 * @param params
 */
export const queryPlanItemList = params => {
    return axios({
        url: "/api/store/queryNewPlanItem",
        method: "get",
        params
    });
};
/*
/!***
 * 网上商城 -- 查询计划条目
 * @param params
 *!/
export const queryPlanItemList = params => {
    return axios({
        url: "/api/mcn/wscg/resultNotice/queryPlanItem",
        method: "get",
        params
    });
};*/

/***
 * 添加计划
 * @param data
 */
export const addPlanAndItem = data => {
    return axios({
        url: "/api/plan/planAndItem/add",
        method: "post",
        data
    });
};
/***
 * 编辑计划
 * @param data
 */
export const editPlanAndItem = data => {
    return axios({
        url: "/api/plan/planAndItem/edit",
        method: "post",
        data
    });
};

/***
 * 编辑计划
 * @param data
 */
export const plan_and_planItem = planId => {
    return axios({
        url: `/api/plan/view/plan_and_planItem/${planId}`,
        method: "get"
    });
};
/***
 * 查询某一具体组织
 * @param data
 */
export const dept_list = params => {
    return axios({
        url: `/api/common/dept/this_level/subordinate/list`,
        method: "get",
        params
    });
};
/***
 * 填报单位下的所有组织
 * @param data
 */
export const orglist = params => {
    return axios({
        url: `/api/dept/list`,
        method: "get",
        params
    });
};
/***
 * 编辑计划 获取默认信息
 * @param data
 */
export const nomalInformation = () => {
    return axios({
        url: `/api/plan/information`,
        method: "get"
    });
};

/***
 * 查看计划条目
 * @param data
 */
export const getPlanItemIfo = params => {
    return axios({
        url: "/api/plan/view",
        method: "get",
        params
    });
};


/***
 * 删除计划
 * @param data
 */
export const deletePlan = id => {
    return axios({
        url: `/api/plan/delete/${id}`,
        method: "get"
    });
};
/***
 * 删除计划
 * @param data
 */
export const revokePlan = planId => {
    return axios({
        url: `/api/plan/revoke/${planId}`,
        method: "get"
    });
};

/***
 * 新增或编辑计划条目
 * @param data
 */
export const addOrEditPlanItem = data => {
    return axios({
        url: "/api/plan/item/add",
        method: "post",
        data
    });
};


/***
 * 获取单位
 * @param data
 */
export const getUnit = data => {
    return axios({
        url: "/api/common/unit",
        method: "get",
        data
    });
};


/***
 * 导入计划目录
 * @param data
 */
export const importPlanItem = data => {
    return axios({
        url: "/api/plan/planItem/import",
        method: "post",
        params:data,
        // onUploadProgress:(e)=>{
        //     console.log(e)
        // }
    });
};



/***
 * 获取单位
 * @param data
 */
export const getPlanItemList = params => {
    return axios({
        url: "/api/plan/items",
        method: "get",
        params
    });
};


/***
 * 提交采购计划
 * @param params
 */
export const submitPlan = params => {
    return axios({
        url: "/api/plan/submit/"+params.id,
        method: "put",
        
    });
};

/***
 * 删除计划条目
 * @param params
 */
export const deletePlanItem = params => {
    return axios({
        url: "/api/plan/item/remove/"+params.id,
        method: "delete",
        
    });
};


/***
 * 保存计划条目列表
 * @param params
 */
export const savePlanItemList = params => {
    return axios({
        url: "/api/plan/item/addList/"+params.id,
        method: "put",
    });
};


/***
 * 获取计划及条目
 * @param data
 */
export const getPlanAndItems = params => {
    return axios({
        url: "/api/plan/project/plans",
        method: "get",
        params
    });
};
/***
 *  作废计划条目
 * @param params
 */
export const cancelPlanItem = params => {
    return axios({
        url: "/api/plan_item/cancel",
        method: "get",
        params
    });
};
/***
 *  作废计划条目
 * @param params
 */
export const cancel_reason = params => {
    return axios({
        url: "/api/plan/view/plan_item/cflm/cancel_reason",
        method: "get",
        params
    });
};

/***
 *  作废计划条目  龙蟒
 * @param params
 */
export const cancelPlanItemCflm = params => {
    return axios({
        url: "/api/plan/plan_item/cflm/cancel",
        method: "get",
        params
    });
};


/***
 * 获取计划条目详情
 * @param data
 * @returns {*|Promise<any>}
 */
export  const getPlanItemDetails =(params)=> {
    return axios({
        url: '/api/plan/item/view',
        method: 'get',
        params
    })
};

/***
 * 获取待办计划条目详情
 * @param data
 * @returns {*|Promise<any>}
 */
export  const getPlanItemDetailsIntoDo =(params)=> {
    return axios({
        url: '/api/plan_item/audit/planItem',
        method: 'get',
        params
    })
}

/***
 * 获取待办计划条目基本信息详情
 * @param data
 * @returns {*|Promise<any>}
 */
export  const getPlanItemBasicInfoIntoDo =(params)=> {
    return axios({
        url: '/api/plan_item/planItem/BasicMessage',
        method: 'get',
        params
    })
}

/***
 * 导出明细
 * @param data
 * @returns {*|Promise<any>}
 */
export  const exportPlanItem =params=> {
    return axios({
        url: '/api/plan/item/list/export',
        method: 'get',
        responseType:'blob',
        params
    })
};
/***
 * 导出数据Excel
 * @param data
 * @returns {*|Promise<any>}
 */
export  const exportExcelData =params=> {
    return axios({
        url: '/api/plan/export/data',
        method: 'get',
        responseType:'blob',
        params
    })
}
/***
 * 立项--下载采购计划
 * @param data
 * @returns {*|Promise<any>}
 */
export  const exportExcelcflm =params=> {
    return axios({
        url: '/api/plan/view/plan_item/cflm/export',
        method: 'get',
        responseType:'blob',
        params
    })
}

/***
 * 导入采购计划采购明细模版
 * @param data
 * @returns {*|Promise<any>}
 */
export  const importPlanItemTemplate =data=> {
    return axios({
        url: '/api/plan/importPlanItem',
        method: "post",
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
//获取龙蟒计划条目
export  const getLMPlanItem =(params)=> {
    return axios({
        // url: `/api/view/plan_item/cflm/${params.planId}`,
        url: `/api/plan/view/plan_item/cflm`,
        method: 'get',
        params
    })
}
